import React from 'react';
import{Link} from "react-router-dom";
const Footer = () => {
    const currentYear = (new Date().getFullYear())
const yearTxt = currentYear === 2022 ? "2022" : "2022 - "+currentYear
    return (
        <React.Fragment>

<footer>
   <section className="footer-area">
      <div className="container .container-width">
         <div className="row mt-30">
            <div className="col-md-3">
               <div className="lgx-footer-single">
                  <a className="logo" href="/"><img src="assets/img/logo/white-logo.png" alt="Logo"/></a>
               </div>
               <div className="footer-text">
                  <p>  We are a unit of seasons catering services offering you the famous dishes in your city at your comfort!</p>
               </div>
            </div>
            <div className="col-md-3 col-sm-4">
               <div className="lgx-footer-single">
                  <h2 className="title"> Quick Links</h2>
                  <ul className="list-unstyled">
                     <li><Link to="/">Home</Link></li>
                     <li><Link to="/about-us">About Us</Link></li>
                     <li><Link to="/">Menu</Link></li>
                     <li><Link to="/contact-us">Contact Us</Link></li>                 
                  </ul>
               </div>
            </div>
            <div className="col-md-3 col-sm-4">
               <div className="lgx-footer-single">
                  <h2 className="title">Opening Time</h2>
                  <div className="opening-time-single">
                  Monday-Friday
                     <span><i>12:00</i> Pm - <i>11:45</i> PM</span>
                  </div>
                  <div className="opening-time-single">
                     Saterday- Sunday
                     <span><i>10:00</i> Am - <i>11:30</i> PM</span>
                  </div>
               </div>
            </div>
            <div className="col-md-3 col-sm-4">
               <div className="lgx-footer-single">
                  <h2 className="title"> Social Media</h2>
                  <div className="footer-social-icons clearfix">
                     <span className="social-facebook fb-bg"><a  data-bs-toggle="tooltip"  data-bs-placement="bottom" title="Facebook" href="#" ><i className="fa fa-facebook icon-color"></i></a></span>               
                     <span className="social-instagram insta-bg"><a data-bs-toggle="tooltip"  data-bs-placement="bottom" title="Instagram" href="#" ><i className="fa fa-instagram icon-color"></i></a></span>
            {/* <span className="social-instagram"><a data-bs-toggle="tooltip"  data-bs-placement="bottom" title="Instagram" href="#" ><i className="fa fa-twitter icon-color"></i></a></span>
                     <span className="social-instagram linkedin-bg"><a data-bs-toggle="tooltip"  data-bs-placement="bottom" title="Instagram" href="#" ><i className="fa fa-linkedin icon-color"></i></a></span> */}
                  </div>
               </div>
            </div>
            
         </div>
         <div className="lgx-footer-bottom">
               <div className="copyright text-center">
                  <p>
                     Copyright ©  { yearTxt }, <a href="#"> Dragon’s Den </a>. <span>All Rights Reserved.</span>
                  </p>
               </div>
            </div>
      </div>
   </section>
</footer>


<div id="social_icon" className="social-icon">        
    <a href="#" id="sidebar" className="facebook" ><i className="fa fa-facebook" aria-hidden="true"></i></a>
    <a href="#" id="sidebar_insta" className="insta"><i class="fa fa-instagram" aria-hidden="true"></i></a>
</div>
<div id="sy-whatshelp">
            <a className="sywh-open-services" id="icon_hide" data-tooltip="Order Now" data-placement="left"  href="https://api.whatsapp.com/send?phone=9311055455" target="_blank">
				<i className="fa fa-whatsapp"></i></a>
</div>

</React.Fragment>
 );
}

export default Footer;