import React from 'react';
import { Helmet } from 'react-helmet';
import Testimonial from '../pages/Testimonial';
const About = () => {

    return (  
        <React.Fragment>
             <Helmet>
              <title>Dragon’s Den | About Us</title>
              </Helmet>
          {/* --------------top section------------- */}
            <section>
                <div className="lgx-banner lgx-banner-inner">
                    <div className="lgx-page-inner">
                        <div className="container container-width">
                            <div className="row">
                            <div className="col-xs-12">
                                <div className="lgx-heading-area">
                                    <div className="lgx-heading lgx-heading-white">
                                        <h2 className="heading-about">About Us</h2>
                                    </div>                    
                                </div>
                            </div>
                            </div>
                        
                        </div>         
                    </div>     
                </div>
            </section>


            <section>
                <div id="lgx-about" className="lgx-about lgx-about2">                
                    <div className="lgx-inner">
                    <div className="container container-width">
                        <div className="row">
                            <div className=" col-md-6">
                                <div className="about-img">
                                <img src="assets/img/fried-rice.webp" alt=""/>                          
                                </div>
                            </div>
                            <div className=" col-md-6">
                                <div className="lgx-about-area lgx-about-area-video">
                                <div className="lgx-heading">
                                    <h2 className="heading-title">Know about us! </h2>
                                </div>
                                <div className="lgx-about-content">
                                    <div className="about-text">
                                        <p >We are a unit of seasons catering services offering you the famous dishes in your city at your comfort! Want your taste buds to experience the joys of the finest taste including everyone’s favourite- tandoori Tikka,the divine sizzlers, the perfect blend of noodles and vegetables in Chopsuey?   </p>
                                        <p>Enjoy these incredible tastes with a side drink like sweet shakes and beverages at Dragon’s Den!</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>                 
                    </div>               
                    </div>           
                </div>
            </section>
            
            <section className="service-area">
                <div className="container container-width">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="services-heading">
                            <h2>Special Services </h2>
                        </div>
                        <div className="services-text">
                            <p>We provide multi cuisine food at our place on special orders. If you make a booking of 25/30 or more packed food per head basis you can get even more delicacies. If you are looking for quality food then what can be better than Dragon’s Den? We provide </p>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="about-top-single">
                            <a href="#"><img src="assets/img/Indianfood.webp" alt=""/></a>
                            <h3 className="title"><a href="#">Indian</a></h3>                     
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="about-top-single">
                            <a href="#"><img src="assets/img/Mughlai-food.webp" alt="about-icon"/></a>
                            <h3 className="title"><a href="#">Mughlai</a></h3>                   
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="about-top-single">
                            <a href="#"><img src="assets/img/Chinese-food.webp" alt="about-icon"/></a>
                            <h3 className="title"><a href="#">Chinese</a></h3>
                            
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="about-top-single">
                            <a href="#"><img src="assets/img/continental-food.webp" alt="about-icon"/></a>
                            <h3 className="title"><a href="#">Continental</a></h3>
                            
                        </div>
                    </div>
                    </div>
                </div>
                
            </section>
            
            <section className="function-area">
                <div className="container container-width">
                    <div className="row">
                    <div className="col-md-6">
                        <div className="function-memory">
                            <h3>Make Your Functions Memorable and Affordable</h3>
                            <p>Now you can enjoy your Functions and special occasions with the most exotic food in town! Make your day more memorable with incredibly delicious yet affordable food that is delivered directly to your doorstep. You can book us for Weddings, Greh Pravesh, Birthday Parties, Get together and many other memorable events!</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="parties-food">
                            <img src="assets/img/schezwan-noodles.webp" alt=""/>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        <section>
            <div id="lgx-testimonial" className="lgx-testimonial">
                <div className="lgx-inner">
                    <div className="container container-width">
                        <div className="row">
                        <div className="col-xs-12">
                            <div className="lgx-heading">
                                <div className="testimonial-heading">
                                    <h2 >Dragon’s Den Testimonials </h2>
                                    <p>Let’s Hear it from the Customers</p>
                                </div>                     
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                    <Testimonial/>             
                    </div>
                </div>        
            </div>     
        </div>
    </section>

        </React.Fragment>
      );
}
export default About;