import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
const Gallery = () => {
   const [feed,setData] =useState([])
   var fields = 'id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username';
   var token = 'IGQVJVZAmwzM0V2ZA3dlV1FUbkh0YjhrWjR3VXVUeld1NlpNQmozWVZAJNXEtMG1RODZAlYUdvNEpfZAEZA0bmVhZAEpYZAkZA4S2daTlVmcnU3VGloNTNNQTM2TmdZAN0c5VE93RDR3X1pJQ2VEd2hxd0J4a0dNbwZDZD';
   var limit = '100';
   var targetur = `https://graph.instagram.com/me/media?fields=${fields}&access_token=${token}&limit=${limit}`
  useEffect(()=>{
    fetch(targetur).then((result)=>{
      result.json().then((resp)=>{
        //console.warn("result", resp)
        setData(resp.data)
      })
    })
  },[])
  //console.warn(feed)
return (
<React.Fragment>
   <Helmet>
      <title>Dragon’s Den | Gallery</title>
   </Helmet>
   <section>
      <div className="lgx-banner lgx-banner-inner">
         <div className="lgx-page-inner">
            <div className="container container-width">
               <div className="row">
                  <div className="col-xs-12">
                     <div className="lgx-heading-area">
                        <div className="lgx-heading lgx-heading-white">
                           <h2 className="heading-about">Gallery</h2>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section className="gallery-area clearfix">
      <div className='container clearfix'>
         <div className='row'>
            {feed.map((item, index) => {
               if(item.media_type === 'VIDEO') {
               return(
            <div class="col-md-3" key={index}>
               <div class="gallery">
                  <aside class="gallery-list layout-2">
                     <a href={item.media_url} class="image-holder" rel="gallery" data-fancybox="gallery">
                        <img src={item.thumbnail_url} />
                        <div class="frame"></div>
                        <div class="inner">
                           <div class="info">
                              <h6>{item.caption}</h6>
                              <i class="fa fa-eye" aria-hidden="true"></i>                         
                           </div>
                        </div>
                     </a>
                  </aside>
               </div>
            </div>
            )
         } else {
            return(
         <div class="col-md-3" key={index}>
            <div class="gallery">
               <aside class="gallery-list layout-2">
                  <a href={item.media_url} class="image-holder" rel="gallery" data-fancybox="gallery">
                     <img src={item.media_url} />
                     <div class="frame"></div>
                     <div class="inner">
                        <div class="info">
                           <h6>{item.caption}</h6>
                           <i class="fa fa-eye" aria-hidden="true"></i>                         
                        </div>
                     </div>
                  </a>
               </aside>
            </div>
         </div>
         )
         }
         }
            )
            }
         </div>
      </div>
   </section>
</React.Fragment>
)
}
export default Gallery