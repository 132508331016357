import React from 'react';
import{Link} from "react-router-dom";
import { useLocation } from "react-router-dom";

   

const Header = () => {
     //assigning location variable
     const location = useLocation();

     //destructuring pathname from location
     const { pathname } = location;
 
     //Javascript split method to get the name of the path in array
     const splitLocation = pathname.split("/");
    return (
        <React.Fragment>
                <header>
    <div id="lgx-header" className="lgx-header">
        <div className="lgx-header-position">  
            <div className="lgx-container">        
                <nav className="navbar navbar-default lgx-navbar">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                    
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    </button>
                    <div className="lgx-logo">                    
                        <Link to="/" className="lgx-scroll">
                        <img src="assets/img/logo/logo-2.svg" alt=""/>
                        </Link>
                    </div>
                </div>
                <div id="navbar" className="navbar-collapse mynav collapse lgx-collapse">
                    <ul className="nav navbar-nav lgx-nav">
                        <li className={splitLocation[1] === "" ? "active" : ""}><Link to="/" >Home</Link></li>    
                        <li className={splitLocation[1] === "about-us" ? "active" : ""}><Link to="/about-us" className="lgx-scroll"  > About Us</Link></li>  
                        {/* <li><Link to="/javascript:void(0);" className="lgx-scroll">Menu</Link></li>               */}
                        <li><a  className="lgx-scroll " href="assets/menu/dragons_den_menu_card.pdf"  target={'_blank'}>Menu</a></li>
                        <li className={splitLocation[1] === "gallery" ? "active" : ""}><Link to="/gallery" className="lgx-scroll"  > Gallery</Link></li>  
                        <li className={splitLocation[1] === "contact-us" ? "active" : ""}><Link to="/contact-us"  className="lgx-scroll">Contact Us</Link></li>
                        {/* <li><a className="lgx-scroll" href="contact-us.php">Contact Us</a></li> */}
                        
                    </ul>
                    
                </div>
                
                </nav>
            </div>
            
        </div>
    </div>
</header>
        </React.Fragment>
    );
}
export default Header;