import React from "react";
import Sliderbtn from './Sliderbtn';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Slider = () => {
      const options = {
      loop: true,
      dots:false,
      nav: true,
      navText : ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      margin: 0,
      items: 1,
      autoplay: false,
      responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
      }
    };
    return (
      <div className="lgx-slider">
         <div className="lgx-banner-style">
         <div className="lgx-inner">
      <OwlCarousel className="owl-theme" {...options}>
         
            
          
             <div className="lgx-item-common">
                  <div className="col-sm-12g">
                     <div className="slider-text-single">
                        <figure>
                           <img src="assets/img/banner-new-1.webp" alt="slide"/>
                             <figcaption>
                              <div className="lgx-container">
                                 <div className="lgx-hover-link">
                                    <div className="lgx-vertical">
                                       <div className="lgx-banner-info lgx-banner-info-black lgx-banner-info-bg" >
                                        
                                          <h3 className="subtitle lgx-delay lgx-zoomInUp">Delicious Foods</h3>
                                          <h2 className="title lgx-delay lgx-zoomInDown">Specialty Of Foods</h2>
                                          <Sliderbtn/>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </figcaption>
                        </figure>
                     </div>
                  </div>
                </div>
                <div className="lgx-item-common">
                  <div className="col-sm-12g">
                     <div className="slider-text-single">
                        <figure>
                        <img src="assets/img/banner-new-2.webp" alt="slide"/>
                             <figcaption>
                              <div className="lgx-container">
                                 <div className="lgx-hover-link">
                                    <div className="lgx-vertical">
                                       <div className="lgx-banner-info lgx-banner-info-black lgx-banner-info-bg" >
                                          <h3 className="subtitle lgx-delay lgx-rotateIn">North Indian &amp; Chinese</h3>
                                          <h2 className="title lgx-delay lgx-rotateIn">Delicacy at your Door Step</h2>
                                          <Sliderbtn/>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </figcaption>
                        </figure>
                     </div>
                  </div>
                </div>
                <div className="lgx-item-common">
                  <div className="col-sm-12g">
                     <div className="slider-text-single">
                        <figure>
                        <img src="assets/img/banner-new-3.webp" alt="slide"/>
                             <figcaption>
                              <div className="lgx-container">
                                 <div className="lgx-hover-link">
                                    <div className="lgx-vertical">
                                       <div className="lgx-banner-info lgx-banner-info-black lgx-banner-info-bg" >                                        
                                       <h3 className="subtitle lgx-delay lgx-rotateIn">Delicious Foods</h3>
                                          <h2 className="title lgx-delay lgx-rotateIn">Specialty Of Foods</h2>
                                          <Sliderbtn/>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </figcaption>
                        </figure>
                     </div>
                  </div>
                </div>
          

     

      </OwlCarousel>
      
      </div>
        </div>
        </div>
    );
  };
  export default Slider;

