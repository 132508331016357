import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Testimonial = () => {
const options = {
loop: true,
dots:true,
margin: 10,
items: 2,
autoplay: true,
responsive:{
   0:{
       items:1
   },
   600:{
       items:1
   },
   1000:{
       items:2
   }
 }
};
return (
<React.Fragment>
   <OwlCarousel className="owl-theme" {...options}>
      <div className="item">
         <div className="testimonial-card">
            <blockquote className="lgx-testi-single">
               <p><span>Great Service at an Affordable Price </span>Dragon’s Den is a paradise for vegetarians!!! A great option for people living in Greater Noida West.  Had heard a lot about their Chinese platter so ordered some of the dishes including Hakka Noodles, Paneer Steamed Momos & Chilli Garlic Rice. Must say, all of the dishes had a great taste and their prices were affordable too! Incredible services at a reasonable price.  </p>
               <div className="author">
                  
                  <h4><a href="#"></a>Jonathon Doe</h4>
                  <div className="rate">
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star"></i>
                  </div>
               </div>
            </blockquote>
         </div>
      </div>
      <div className="item">
         <div className="testimonial-card">
            <blockquote className="lgx-testi-single">
               <p><span>Amazing taste, authentic flavours! </span> One of my friends recommended Dragon’s Den and since then this place has become my go-to option for ordering food. They offer an amazing variety of dishes. Their Kadhai Paneer and Dal Makhni are favourites of many.  Order from them once and I’m sure you won’t regret it!  </p>
               <div className="author">
                  
                  <h4><a href="#"></a>Jonathon Doe</h4>
                  <div className="rate">
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star"></i>
                  </div>
               </div>
            </blockquote>
         </div>
      </div>
      <div className="item">
         <div className="testimonial-card">
            <blockquote className="lgx-testi-single">
               <p><span>Diverse Menu – Endless Variety </span>Had booked their services for my son’s birthday party. Everyone at the function was impressed by the quality and taste of the food. What delicious food do they serve! Have no complaints about any of their dishes. Thank You Dragon’s Den for excellent services at great prices.  </p>
               <div className="author">
                  
                  <h4><a href="#"></a>Jonathon Doe</h4>
                  <div className="rate">
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star"></i>
                  </div>
               </div>
            </blockquote>
         </div>
      </div>
      <div className="item">
         <div className="testimonial-card">
            <blockquote className="lgx-testi-single">
               <p><span>Mouth-watering taste responsibly delivered! </span>I have tried many dishes from Dragon’s Den and all of them were superb. Ordering food from them has never been a hassle. Always got my food freshly delivered on time. Full marks for their services and quality of food. </p>
               <div className="author">
                  
                  <h4><a href="#"></a>Jonathon Doe</h4>
                  <div className="rate">
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star"></i>
                  </div>
               </div>
            </blockquote>
         </div>
      </div>
      <div className="item">
         <div className="testimonial-card">
            <blockquote className="lgx-testi-single">
               <p><span>Fabulous dishes, fine taste and outstanding services – all packed in one! </span>Thanks to Dragon’s Den for helping us have a terrific time at our Get Together. The food was extremely delicious. Received praises from all of our guests. We absolutely look forward to ordering the food again.  </p>
               <div className="author">
                  <h4><a href="#"></a>Jonathon Doe</h4>
                  <div className="rate">
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star active"></i>
                     <i className="fa fa-star"></i>
                  </div>
               </div>
            </blockquote>
         </div>
      </div>
   </OwlCarousel>
</React.Fragment>
)
}
export default Testimonial