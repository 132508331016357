import React from 'react';
import { Helmet } from 'react-helmet';

const Contact = () => {
    
    return (  
        <React.Fragment>
            <Helmet>
              <title>Dragon’s Den | Contact Us</title>
              </Helmet>
            {/* -------top area--------------- */}
            <section>
                <div className="lgx-banner lgx-banner-inner">
                    <div className="lgx-page-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="lgx-heading-area">
                                        <div className="lgx-heading lgx-heading-white">
                                            <h2 className="heading-about">Contact Us</h2>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>                            
                        </div>                       
                    </div>                    
                </div>
            </section>
         


            {/* ---------------top area end----------------- */}

            <section className="contact-area">
                <div className="container">
                    <div className="row">
                    <div className="col-md-6">
                        <div className="contact-heading">
                            <h3>If you want to contact us</h3>
                        </div>
                        <div className="contact-text">
                            <h4>Phone No:</h4>
                            <div className="contact-icon">
                                <ul>
                                <li> <a href="tel:9717718321"><i className="fa fa-phone" aria-hidden="true"></i>9717718321</a></li>
                                <li><a href="https://api.whatsapp.com/send?phone=9311055455"><i className="fa fa-whatsapp" aria-hidden="true"></i>9311055455</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="contact-text">
                            <h4>Email:</h4>
                            <div className="contact-icon">
                                <ul>
                                <li> <a href="mailto:dragonsden.noida@gmail.com"><i className="fa fa-envelope-o" aria-hidden="true"></i>dragonsden.noida@gmail.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="contact-text">
                            <h4>Opening time: </h4>
                            <div className="contact-icon">
                                <ul>
                                <li>
                                    <p><i className="fa fa-clock-o" aria-hidden="true"></i>  Monday-Friday: 12PM- 11:45PM</p>
                                    <p><i className="fa fa-clock-o" aria-hidden="true"></i> Saturday, Sunday : 10AM- 11:30PM</p>
                                </li>
                                </ul>
                            </div>
                        </div>
                        <div className="contact-text">
                            <h4> Address:</h4>
                            <div className="contact-icon">
                                <ul>
                                <li>
                                    <p><i className="fa fa-map-marker" aria-hidden="true"></i>  A7, Amaatra Homes, Greater Noida West, 201308</p>
                                </li>
                                </ul>
                            </div>
                        </div>
                        <div className="contact-text">
                            <h4>Social Media:</h4>
                            <div className="footer-social-icons social-media">
                                <span className="social-facebook fb-bg"><a  data-bs-toggle="tooltip"  data-bs-placement="bottom" title="Facebook" href="#" ><i className="fa fa-facebook icon-color" ></i></a></span>               
                                <span className="social-instagram insta-bg" ><a data-bs-toggle="tooltip"  data-bs-placement="bottom" title="Instagram" href="#" ><i className="fa fa-instagram icon-color"></i></a></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="contcat-card">
                            <div className="contact-form ">                            
                                <div className="row">
                                <form action="#" method="post">
                                    <div className="col-md-12">
                                        <input type="text" name="username" className="form-control" placeholder="Name" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="email" className="form-control" placeholder="E-mail"/>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="number" name="mobile-number"  className="form-control" placeholder="Mobile No."/>
                                    </div>
                                    <div className="col-md-6">
                                        <select name="" className="form-control" id="">
                                            <option value="0" selected>Ordering Food for:</option>
                                            <option value="">Individual order</option>
                                            <option value="">Birthday party</option>
                                            <option value="">Kitty Party</option>
                                            <option value="">Get together</option>
                                            <option value="">Corporate Party</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <select name="" className="form-control" id="">
                                            <option value="0" selected>PAX:</option>
                                            <option value="">8-10</option>
                                            <option value="">11-20</option>
                                            <option value="">21-30</option>
                                            <option value="">30+</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <textarea name="message" className="form-control" placeholder="Message"></textarea>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="sub-btn">
                                            <button type="button" className="kf-btn"><span>Submit</span></button>
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
      </section>


        </React.Fragment>
      );
}
export default Contact;