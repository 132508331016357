import React, {Component} from 'react';

export default class Modal extends Component {
render(){
return (
    <React.Fragment>
     <div className="modal fade" id="contact-modal" tabindex="-1" role="dialog" aria-labelledby="modalLabelLarge" aria-hidden="true">
   <div className="modal-dialog modal-lg">
      <div className="modal-content">
         <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">X</button>
         </div>
         <div className="contcat-card-modal">
            <div className="contact-form ">
               <h3>Contact Us</h3>
               <div className="row">
                  <form action="" method="post">
                     <div className="col-md-12">
                        <input type="text" name="username" className="form-control" placeholder="Name" />
                     </div>
                     <div className="col-md-6">
                        <input type="text" name="email" className="form-control" placeholder="E-mail"/>
                     </div>
                     <div className="col-md-6">
                        <input type="number" name="mobile-number"  className="form-control" placeholder="Mobile No."/>
                     </div>
                     <div className="col-md-6">
                        <select name="" className="form-control" id="">
                           <option value="0" selected>Ordering Food for:</option>
                           <option value="">Individual order</option>
                           <option value="">Birthday party</option>
                           <option value="">Kitty Party</option>
                           <option value="">Get together</option>
                           <option value="">Corporate Party</option>
                        </select>
                     </div>
                     <div className="col-md-6">
                        <select name="" className="form-control" id="">
                           <option value="0" selected>PAX:</option>
                           <option value="">8-10</option>
                           <option value="">11-20</option>
                           <option value="">21-30</option>
                           <option value="">30+</option>
                        </select>
                     </div>
                     <div className="col-md-12">
                        <textarea name="message" className="form-control" placeholder="Message"></textarea>
                     </div>
                     <div className="col-md-4">
                        <div className="sub-btn">
                           <button type="submit">Submit</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
    </React.Fragment>
)
}
}

