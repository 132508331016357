import React from 'react';
import{Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import Slider from '../pages/Slider';
import Modal from '../pages/Modal';
import Testimonial from '../pages/Testimonial';
import $ from "jquery";
const Front = () => {



    $(document).ready(function($){
        $('.swiggy-btn').on({
             'click': function(){
                 $('#dragon_x_img').attr('src','assets/img/swiggy-scanner.webp');
             }
         });         
        $('.paytm-btn').on({
             'click': function(){
                 $('#dragon_x_img').attr('src','assets/img/paytm-scanner.webp');
             }
         });       
        });


    return (

        <React.Fragment>    
               <Helmet>
              <title>Dragon’s Den</title>
              </Helmet>       
                <section>
                <Slider/>
                </section>
        {/* ---------slider end---------------- */}

{/* ------------------About area start------------- */}
        <section>
            <div id="lgx-about" className="lgx-about lgx-about2">                
                <div className="lgx-inner">
                    <div className="container container-width">
                        <div className="row">                        
                            <div className=" col-md-6">
                                <div className="lgx-about-area lgx-about-area-video">
                                    <div className="lgx-heading">
                                        <h2 className="heading-title">Know about us! </h2>
                                    </div>
                                    <div className="lgx-about-content">
                                        <div className="about-text">
                                        <p >We are a unit of seasons catering services offering you the famous dishes in your city at your comfort! Want your taste buds to experience the joys of the finest taste including everyone’s favourite- tandoori Tikka,the divine sizzlers, the perfect blend of noodles and vegetables in Chopsuey?   </p>
                                        <p>Enjoy these incredible tastes with a side drink like sweet shakes and beverages at Dragon’s Den!</p>
                                        </div>
                                        <div className="read-more">
                                         <Link to="/about-us" class="kf-btn"><span>Read More</span></Link>                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div className=" col-md-6">
                            <div className="about-img sb-illustration-1-2">
                                <img src="assets/img/12.webp" alt=""/>
                                <div className="about-img-2 rotate">
                                    <img src="assets/img/img_01.webp" alt=""/>
                                    </div>
                                    <div className="sb-cirkle-4"></div>
                                </div>
                            </div>                        
                        </div>                        
                    </div>                    
                </div>                
            </div>
        </section>

     {/* -------------------About area end-----------    */}

{/* -----------------------Testimonial start----------------------- */}
<section>
    <div id="lgx-testimonial" className="lgx-testimonial">
        <div className="lgx-inner">
            <div className="container container-width">
                <div className="row">
                <div className="col-xs-12">
                    <div className="lgx-heading">
                        <div className="testimonial-heading">
                            <h2 >Dragon’s Den Testimonials </h2>
                            <p>Let’s Hear it from the Customers</p>
                        </div>                    
                    </div>
                </div>
                </div>
            
                <div className="row">
                <Testimonial/>             
                </div>
            </div>       
        </div>     
    </div>
</section>
{/* ---------Testimonial end-------------- */}

{/* ------------------QR area start------------------------- */}
<section className="qr-area">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <div className="qr-heading">
               <h2>Easy Payment Method </h2>
            </div>
         </div>
         <div className="col-md-6">
            <div className="qr-img">
              <a href="#"> <img  id="dragon_x_img" src="assets/img/swiggy-scanner.webp" alt=""/></a>
             
            </div>
         </div>
         <div className="col-md-6">
            <div className="qr-text">
               <h4>You can pay for your order through Paytm or other UPI Payment modes! </h4>
               <p>Scrumptious food at your Doorstep. Your convenience is our priority!</p>
            </div>
            <div className="swiggy-btn-z">
              
               <button className="swiggy-btn"><img src="assets/img/swiggy-new-btn.svg"/> </button>
               <button className="paytm-btn"><img src="assets/img/paytm-new-btn.svg"/> </button>
            </div>
         </div>
        
      </div>
   </div>
</section>
{/* -----------QR area end-------------------- */}

{/* -----------cta start----------------- */}
<section className="cta-area">
   <div className="container">   
      <div className="row">         
         <div className="col-md-8">
            <div className="cta-heading ">
               <h2>Packed food for large gatherings upto 50-60 PAX</h2>
            </div>
         </div>
        
         <div className="col-md-4">
            <div className="cta-btn">
               <a href="#" data-toggle="modal" data-target="#contact-modal" className="kf-btn"><span>Enquire now</span></a>
            </div>
         </div>
      </div>
   </div>
</section>
<Modal/>

{/* ---------------cta end------------------ */}

</React.Fragment>
    )
}
export default Front;