import React from 'react';
const Sliderbtn = () => {
    return (
        <div className="btn-area lgx-delay lgx-zoomInUp">
          <a className="kf-btn" href="https://api.whatsapp.com/send?phone=9311055455" target="_blank"><span>Chinese </span></a>
          <a className="dark-btn" href="https://api.whatsapp.com/send?phone=9311055455" target="_blank"><span>North Indian</span></a>
          <a className="fill-btn" href="https://api.whatsapp.com/send?phone=9311055455" target="_blank"><span> Full Menu</span></a>
      </div>
    );
  };
  
  export default Sliderbtn;